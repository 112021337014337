import {IIndividu,Individu} from "@julienbdx/nous-sommes-common";
import {IHydraResponse} from "@julienbdx/nous-sommes-common/lib/classes/hydra";

export interface IIndividuAdmin extends IIndividu{
    visible:boolean;
}


class IndividuAdmin extends Individu implements IIndividuAdmin {
    visible:boolean = false ;
    static fromHydra(data: any):IndividuAdmin {
        const individuAdmin = super.fromHydra(data) as IndividuAdmin ;
        individuAdmin.visible = data.visible ;
        return individuAdmin ;
    }
}

export const individuAdminFromHydra = (hydraResponse: IHydraResponse | any[]): IndividuAdmin[] => {
    return 'hydra:member' in hydraResponse
        ? hydraResponse['hydra:member'].map((item: any) => IndividuAdmin.fromHydra(item))
        : hydraResponse.map((item: any) => IndividuAdmin.fromHydra(item));
};