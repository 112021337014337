import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import Form from 'react-bootstrap/Form'
import {Alert, Col, Row} from "react-bootstrap";
import './communaute.scss'
import Button from "react-bootstrap/Button";
import {Forme} from "@julienbdx/nous-sommes-common/lib/classes/forme";
import {Couleur, couleursFromHydra} from "@julienbdx/nous-sommes-common/lib/classes/couleur";
import {Communaute, fetchApi} from "@julienbdx/nous-sommes-common";
import {ENDPOINT} from "../../../config/config";
import {formesFromHydra} from "@julienbdx/nous-sommes-common/lib/classes/forme";
import {createCommunaute} from "./createCommunaute";

export interface ICommunaute {
    communaute?: Communaute;
}

function AddCommunaute({communaute, setModalEditShow, communautesList, setCommunautesList}: any): JSX.Element {
    const method = communaute ? "PATCH" : "POST"
    // console.log("method :" + method)
    const v = false;
    // console.log(communaute)
    const [formeState, setFormeState] = useState(communaute?.forme.id ?? "0")
    const [couleurState, setCouleurState] = useState(communaute?.couleur.id ?? "0")
    const [nom, setNom] = useState(communaute?.libelle ?? "")
    const [slug, setSlug] = useState(communaute?.slug ?? "")
    const [erreur, setErreur] = useState("")
    const [visible, setVisible] = useState(communaute?.visible ?? false)
    const today = new Date();
    const datePublication = today.toISOString();

    function handleChange(ev: any, item: string) {
        switch (item) {
            case "forme":
                setFormeState(ev.currentTarget.value)
                break;
            case "couleur":
                setCouleurState(ev.currentTarget.value)
                break;
            case "nom":
                setNom(ev.currentTarget.value)
                break;
            case "slug":
                setSlug(ev.currentTarget.value)
                break;
            default :
                console.log("handleChange function in AddCommunaute finiched with a default value please check the index value")
                break;
        }

    }

    const restartAll = () => {
        setFormeState("")
        setCouleurState("")
        setNom("")
        setErreur("")
        setVisible(false)
    }
    const creerCommunaute = () => {
        if (!communaute) setSlug(nom);
        return createCommunaute(communaute?.id, method, nom, formeState, couleurState, visible, datePublication, slug)
    }

    const handleCreerCommunaute = () => {
        // console.log("Demande création communauté", nom, formeState, couleurState, visible, datePublication, nom)

        if (formeState === "0" || formeState === "") {
            setErreur("Forme non sélectionnée")
            return
        }

        if (couleurState === "0" || couleurState === "") {
            setErreur("Couleur non sélectionnée")
            return
        }

        if (nom === "") {
            setErreur("Nom de la communauté non précisé")
            return
        }
        if (communaute && slug === "") {
            setErreur("Slug de la communauté non précisé")
            return
        }
        creerCommunaute().then(
            () => {
                // to chnage the appearance of elment information
                if (method === "PATCH") {
                    setCommunautesList(() => {
                        return communautesList.map((e: any) => {
                            if (e.id === communaute.id) {
                                return {
                                    ...e,
                                    libelle: nom,
                                    visible: visible,
                                    forme:{
                                        id : formeState
                                    },
                                    couleur: {
                                        id : couleurState
                                    },
                                    slug: slug,
                                }
                            }
                            return e;
                        });
                    });
                }
            })
        if (setModalEditShow) setModalEditShow(false);
        restartAll()
    }

    return (
        <div className="communaute-form">
            {/*<h2>Créer une nouvelle communauté</h2>*/}
            <Form>
                {erreur !== ""
                    ? <Alert variant={"danger"} title={"Erreur"}>{erreur}</Alert>
                    : null}
                <Row>
                    <Form.Label column lg={3}>
                        Nom de la communauté
                    </Form.Label>
                    <Col>
                        <Form.Control type="text"
                                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                          handleChange(ev, "nom")
                                      }}
                                      value={nom}
                        />
                    </Col>
                </Row> <br/>

                <Row>
                    <Form.Label column lg={3}>
                        Forme associée
                    </Form.Label>
                    <Col>
                        <ShapesSelect handleChange={handleChange} def={formeState}/>
                    </Col>
                </Row> <br/>

                <Row>
                    <Form.Label column lg={3}>
                        Couleur associée
                    </Form.Label>
                    <Col>
                        <ColorsSelect handleChange={handleChange} def={couleurState}/>
                    </Col>
                </Row> <br/>
                {communaute ?
                    <>
                        <Row>
                            <Form.Label column lg={3}>
                                Slug
                            </Form.Label>
                            <Col>
                                <Form.Control type="text"
                                              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                                  handleChange(ev, "slug")
                                              }}
                                              value={slug}
                                />
                            </Col>
                        </Row> <br/>
                    </>
                    : null}

                <Row>
                    <Form.Label column lg={5}>
                        Cette communauté est-elle en ligne ?
                    </Form.Label>
                    <Col lg={1}>
                        <Form.Check
                            defaultChecked={visible}
                            type="radio"
                            label="Oui"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios1"
                            onClick={() => setVisible(true)}
                        />
                    </Col>
                    <Col lg={1}>
                        <Form.Check
                            defaultChecked={!visible}
                            type="radio"
                            label="Non"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios2"
                            onClick={() => setVisible(false)}
                        />
                    </Col>
                </Row> <br/>
                <Row>
                    <Col lg={7}>

                    </Col>
                    <Col lg={2}>
                        <Button className="btn-danger" onClick={() => {
                            if (communaute) setModalEditShow(false)
                            else restartAll()
                        }}>Annuler</Button>
                    </Col>

                    <Col lg={2}>
                        <Button className="btn-success" onClick={handleCreerCommunaute}>Valider</Button>
                    </Col>
                </Row>

            </Form>
        </div>
    )
}

function ShapesSelect({handleChange, def}: any) {
    // console.log("def shapes :" + def)
    const [formesList, setFormesList] = useState<Forme[]>([])
    useEffect(() => {
        fetchApi(ENDPOINT, "formes", {credentials: "include"})
            .then((res: any) => {
                setFormesList(formesFromHydra(res))
            })
    }, [])

    return (
        formesList.length > 0
            ? <Form.Select className="selectpicker"
                           onChange={(ev: FormEvent<HTMLSelectElement>) => handleChange(ev, "forme")}
                           defaultValue={def}
            >
                <option>-</option>
                {formesList.map((e, index) => (
                    <option key={e.id} value={e.id}>{e.libelle}</option>))}
            </Form.Select>
            : <b>Aucune forme déclarée</b>
    )
}

function ColorsSelect({handleChange, def}: any) {
    // console.log("def colors : " + def)
    const [colorsList, setColorsList] = useState<Couleur[]>([])
    useEffect(() => {
        fetchApi(ENDPOINT, "couleurs", {credentials: "include"})
            .then((res: any) => {
                setColorsList(couleursFromHydra(res))
            })
    }, [])
    return (
        colorsList.length > 0 ?
            <Form.Select className="selectpicker"
                         onChange={(ev: FormEvent<HTMLSelectElement>) => handleChange(ev, "couleur")}
                         defaultValue={def}
            >
                <option>-</option>
                {colorsList.map((e, index) => (<option key={e.id} value={e.id}>{e.libelle}</option>))}
            </Form.Select>
            : <b>Aucune couleur déclarée </b>
    )
}


export default AddCommunaute