import React, {useState} from 'react';
import Menu from "./Menu";
import ItemContainer from "./ItemContainer";
import '../styles/index.scss'
import '../styles/menu.scss'
import '../styles/items.scss'
import '../styles/container.scss'
import FormulaireConnexion from "./Login";


function App() {
    const [toggleState, setToggleState] = useState("visualization")
    const [utilisateur, setUtilisateur] = useState(undefined)

    return (
        utilisateur === undefined ?
            <FormulaireConnexion setUtilisateur={setUtilisateur}/>
            :
            <div className="App">
                <Menu toggleState={toggleState} setToggleState={setToggleState}/>
                <ItemContainer toggle={toggleState}/>
            </div>
    )
}

export default App;
