import {fetchApi} from "@julienbdx/nous-sommes-common";

function SetVisibilityIndividu(endpoint: string | undefined, id: string, oldState: boolean, datePublication: string): any {
    const tochange = !oldState ?
        {
            "visible": true,
            "datePublication": datePublication
        }
        : {
            "visible": false,
        }
    const options = {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(tochange)
    }
    return fetchApi(endpoint, "individus/" + id, options)
}

export default SetVisibilityIndividu