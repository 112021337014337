import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {Form, Alert, Col, Row, FloatingLabel, InputGroup, Button} from "react-bootstrap";
import {Communaute, fetchApi} from "@julienbdx/nous-sommes-common";
import {ENDPOINT} from "../../../config/config";
import {communautesAdminFromHydra} from "../../../classes/CommunauteAdmin";
import '../../../styles/table.scss'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {createIndividu} from "./createIndividu";
import "./individu.scss"
import {TagsInput} from "./TagsInput";
import {TagAdmin} from "../../../classes/tagAdmin";
import {getTags} from "../tag/SettingTag";
import {AddTags} from "../tag/AddTags";
import {getTagsIds} from "../../../utils/TagsTool";

function AddIndividual() {

    const [prenomState, setPrenomState] = useState("")
    const [communauteState, setCommunauteState] = useState("")
    // this list contian the tags given by the usre
    const [tags, setTags] = useState([])
    // const tagetest = ["natation"]
    const [nom, setNom] = useState("")
    const [telephone, setTelephone] = useState("")
    const [mail, setMail] = useState("")
    const [adresse, setAdresse] = useState("")

    //TODO : make the date format and compare function with .getMonth .getDay!
    const today = new Date()
    // console.log(myDate);
    // console.log("the month "+myDate.getMonth());
    const [datePublication, setDatePublication] = useState(today.toISOString())
    const [phrase, setPhrase] = useState("")
    const [urlVidio, setUrlVidio] = useState("")
    const [visible, setVisible] = useState(false)
    const [erreur, setErreur] = useState("")
    /*

    Les hooks doivent toujours être :
    - déclarés dans un composant React
    - initialisés de manière non conditionnelle, c'est-à-dire tu ne peux pas faire :
            if(...) {
                const [visible, setVisible] = useState(false)
            }
            else {
                const [visible, setVisible] = useState(true)
            }
     - tous intialisés à chaque appel du composant React, c'est-à-dire tu ne peux pas faire :
            if(...) {
                return <>...</> // <- là tu sors du composant sans avoir inialisé le useState visible
            }

           const [visible, setVisible] = useState(false)

      C'est valable pour tous les hooks, useState UseEffect useCallback etc...

     */
    const [apiTags,setApiTags] = useState<TagAdmin[]>([])

    // const datePublication = "2021-09-06T17:40:15.349tagsZ"

    function handleChange(ev: any, item: string) {
        switch (item) {
            case "prenom":
                setPrenomState(ev.currentTarget.value)
                break;
            case "communaute":
                setCommunauteState(ev.currentTarget.value)
                break;
            case "nom":
                setNom(ev.currentTarget.value)
                break;
            case "telephone":
                setTelephone(ev)
                break;
            case "mail":
                setMail(ev.currentTarget.value)
                break;
            case "adresse":
                setAdresse(ev.currentTarget.value)
                break;
            case "date":
                setDatePublication(ev.toISOString())
                break;
            case "phrase":
                setPhrase(ev.currentTarget.value)
                break;
            case "url":
                setUrlVidio(ev.currentTarget.value)
                break;
            default :
                console.log("handleChange function in AddIndividual finiched with a default value please check the index value")
                break;
        }

    }

    const creerIndividu = () => {
        getTags(setApiTags)
        const tagsIds = getTagsIds(tags,apiTags)
        return createIndividu(prenomState, communauteState, tagsIds, nom, telephone, mail , adresse, datePublication, phrase, urlVidio);
    }

    const handleCreerIndividu = () => {
        if (prenomState === "") {
            setErreur("prenom non précisé\"")
            return
        }
        if (communauteState === "0" || communauteState === "") {
            setErreur("communauté non sélectionnée")
            return
        }
        if (tags === undefined || tags.length === 0) {
            setErreur("tags non donnée")
            return
        }
        if (nom === "") {
            setErreur("nom non précisé")
            return
        }
        if (telephone === "") {
            setErreur("telephone non donnée")
            return
        }
        if (mail === "") {
            setErreur("mail non donnée")
            return
        }
        if(adresse === ""){
            setErreur("adresse non donnée")
            return;
        }
        if (datePublication === "") {
            setErreur("date de publication non précisé")
            return
        }
        if (phrase === "") {
            setErreur("phrase non donnée")
            return
        }
        if (urlVidio === "") {
            setErreur("URL vidion non présisé")
            return
        }
        /**
         * TODO : fix it in {@link AddTags} function.
         */
        // AddTags(tags)
        creerIndividu()
    }

    return (
        <div className="individu-form">
            {/*<h2>Créer un nouvel Individual </h2>*/}
            <Form>
                {erreur !== ""
                    ? <Alert variant={"danger"} title={"Erreur"}>{erreur}</Alert>
                    : null}
                <div className="shadow p-3 mb-5 bg-white rounded">
                    <h5>visible pour les utilisateurs </h5>
                    <Row>
                        <Form.Label column lg={3}>
                            Prénome
                        </Form.Label>
                        <Col>
                            <Form.Control type="text"
                                          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                              handleChange(ev, "prenom")
                                          }}
                            />
                        </Col>
                    </Row> <br/>
                    <Row>
                        <Form.Label column lg={3}>
                            Communaute
                        </Form.Label>
                        <Col>
                            <CommunauteSelect handleChange={handleChange}/>
                        </Col>
                    </Row> <br/>
                    <Row>
                        <Form.Label column lg={3}>
                            tags
                        </Form.Label>
                        <Col>
                            <TagsInput selectedTags={setTags} tags={tags}/>
                            {/*<p>tags : {tags}</p>*/}
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Form.Label column lg={3}>
                        Nom
                    </Form.Label>
                    <Col>
                        <Form.Control type="text" placeholder="patate"
                                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                          handleChange(ev, "nom")
                                      }}
                        />
                    </Col>
                </Row> <br/>
                <Row>
                    <Form.Label column lg={3}>
                        Téléphone
                    </Form.Label>
                    <Col>
                        <Form.Control
                            type="tel"
                            onChange={(data: ChangeEvent<HTMLInputElement>) => {
                                handleChange(data, "telephone")
                            }}
                        />
                    </Col>

                </Row><br/>
                <Row>
                    <Form.Label column lg={3}>
                        Adresse
                    </Form.Label>
                    <Col>
                        <Form.Control
                            type="text"
                            onChange={(data: ChangeEvent<HTMLInputElement>) => {
                                handleChange(data, "adresse")
                            }}
                        />
                    </Col>

                </Row><br/>

                <Row>
                    <Form.Label column lg={3}>
                        Address mail
                    </Form.Label>
                    <Col>
                        <Form.Control type="email" placeholder="name@example.com"
                                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                          handleChange(ev, "mail")
                                      }}
                        />
                    </Col>
                </Row> <br/>

                <Row>
                    <Form.Label column lg={3}>
                        Date de mis en line
                    </Form.Label>
                    <Col>
                        <DatePicker
                            value={datePublication}
                            //@ts-ignore
                            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                handleChange(ev, "date")
                            }}
                        />
                    </Col>
                </Row><br/>

                <Row>
                    <FloatingLabel controlId="floatingTextarea2" label=" La phrase remarcaple">
                        <Form.Control
                            as="textarea"
                            placeholder="Leave a comment here"
                            style={{height: '100px'}}
                            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                handleChange(ev, "url")
                            }}
                        />
                    </FloatingLabel>
                </Row><br/>

                <Row>
                    <Form.Label column lg={3}>
                        vedio
                    </Form.Label>
                    <Col>
                        <InputGroup hasValidation>
                            <InputGroup.Text>URL</InputGroup.Text>
                            <Form.Control type="text" required
                                          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                              handleChange(ev, "phrase")
                                          }}
                            />
                        </InputGroup>
                    </Col>
                </Row> <br/>
                <Row>
                    <Col lg={7}>

                    </Col>
                    <Col lg={2}>
                        <Button className="btn-danger">Annuler</Button>
                    </Col>

                    <Col lg={2}>
                        <Button className="btn-success" onClick={handleCreerIndividu}>Valider</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}


function CommunauteSelect({handleChange}: any) {
    const [communautesList, setCommunautesList] = useState<Communaute[]>([])

    useEffect(() => {
        fetchApi(ENDPOINT, "communautes", {credentials: "include"})
            .then((res: any) => {
                setCommunautesList(communautesAdminFromHydra(res))
            })
    }, [])

    return (
        <Form.Select className="selectpicker"
                     onChange={(ev: FormEvent<HTMLSelectElement>) => handleChange(ev, "communaute")}
        >
            <option value={"0"}/>
            {communautesList.map(e => (<option key={e.id} value={e.id}>{e.libelle}</option>))}
        </Form.Select>
    )
}


export default AddIndividual
