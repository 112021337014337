import React from "react";
import Table from "react-bootstrap/Table";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import '../../../styles/table.scss'
import {FormControl} from "react-bootstrap";
import {trashIcon} from "../../icons";
import {ENDPOINT} from "../../../config/config";
import SetVisibilityTag from "./SetVisibilityTag";
import DeleteTag from "./DeleteTag";
import {MembersTag} from "./MembersTag";
import "./tag.scss"

function TablTag({tagsList, setTagsList}: any) {
    const [modalShow, setModalShow] = React.useState(false);
    const [tobedelete, setToBeDelete] = React.useState();
    return (
        <div className="TablTag">
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Search..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                />
            </InputGroup>
            <Table responsive="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Visibility</th>
                    <th>Members</th>
                    <th>Delete</th>
                </tr>
                </thead>

                <tbody>
                {tagsList.map((e: any, i: number) => (
                        <TablTagLine
                            key={e.id}
                            i={i + 1}
                            id={e.id}
                            libelle={e.libelle}
                            visible={e.visible}
                            tagsList={tagsList}
                            setTagsList={setTagsList}
                            setToBeDelete={setToBeDelete}
                            setModalShow={setModalShow}
                        />
                    )
                )}
                </tbody>
            </Table>
            <DeleteTag
                show={modalShow}
                onHide={() => setModalShow(false)}
                tobedelete={tobedelete}
                tagsList={tagsList}
                setTagsList={setTagsList}
            />

        </div>
    )
}

function TablTagLine({
                                i,
                                id,
                                libelle,
                                visible,
                                tagsList,
                                setTagsList,
                                setToBeDelete,
                                setModalShow
                            }: any) {
    const members = MembersTag(id);
    return (
        <tr className="TableTagLine">
            <td>{i}</td>
            <td>{libelle}</td>
            <td>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    onChange={() => {
                        SetVisibilityTag(ENDPOINT, id, visible).then(
                            () => {
                                setTagsList(() => {
                                    return tagsList.map((e: any) => {
                                        if (e.id === id) {
                                            e.visible = !visible;
                                        }
                                        return e;
                                    });
                                });
                            })
                    }}
                    checked={visible}
                />
            </td>
            <td>{members != -1 && members}</td>
            <td>
                <Button className="deletebnt" variant="primary"
                        onClick={() => {
                            setModalShow(true);
                            setToBeDelete(id);
                        }}
                > {trashIcon}
                </Button>
            </td>
        </tr>
    )
}


export default TablTag