import React from "react";
import Table from "react-bootstrap/Table";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import '../../../styles/table.scss'
import {FormControl, Modal} from "react-bootstrap";
import {parametresIcon, trashIcon} from "../../icons";
import {ENDPOINT} from "../../../config/config";
import DeleteCommunaute from "./DeleteCommunaute";
import SetVisibilityCommunaute from "./SetVisibilityCommunaute";
import {MembersCommunaute} from "./MembersCommunaute";
import AddCommunaute from "./AddCommunaute";

function TablCommunaute({communautesList, setCommunautesList}: any) {
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [toBeDelete, setToBeDelete] = React.useState();
    const [modalEditShow, setModalEditShow] = React.useState(false);
    const [toBeEdit, setToBeEdit] = React.useState();
    return (
        <div className="TablCommunaute">
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Search..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                />
            </InputGroup>
            <Table responsive="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Edit</th>
                    <th>Visibility</th>
                    <th>Members</th>
                    <th>Delete</th>
                </tr>
                </thead>

                <tbody>
                {communautesList.map((e: any, i: number) => (
                        <TablCommunauteLine
                            key={e.id}
                            i={i + 1}
                            communaute={e}
                            communautesList={communautesList}
                            setCommunautesList={setCommunautesList}
                            setToBeDelete={setToBeDelete}
                            setModalDeleteShow={setModalDeleteShow}
                            setModalEditShow={setModalEditShow}
                            setToBeEdit={setToBeEdit}
                        />
                    )
                )}
                </tbody>
            </Table>

            <DeleteCommunaute
                show={modalDeleteShow}
                onHide={() => setModalDeleteShow(false)}
                toBeDelete={toBeDelete}
                communautesList={communautesList}
                setCommunautesList={setCommunautesList}
            />
            {toBeEdit ?
                <Modal
                    show={modalEditShow}
                    onHide={() => setModalEditShow(false)}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    className="edit-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" >Edit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddCommunaute communaute={toBeEdit} setModalEditShow ={setModalEditShow}  communautesList={communautesList}
                                       setCommunautesList={setCommunautesList}/>
                    </Modal.Body>
                </Modal>
                : null
            }


        </div>
    )
}

function TablCommunauteLine({
                                i,
                                communaute,
                                communautesList,
                                setCommunautesList,
                                setToBeDelete,
                                setModalDeleteShow,
                                setToBeEdit,
                                setModalEditShow
                            }: any) {
    const members = MembersCommunaute(communaute.id);
    // console.log("members : ", members);
    return (
        <tr className="TablCommunauteLine">
            <td>{i}</td>
            <td>{communaute.libelle}</td>
            <td>
                <button className="edit"
                        onClick={() => {
                            setToBeEdit(communaute)
                            setModalEditShow(true)
                            console.log("edit")
                        }}
                >
                    {parametresIcon}
                </button>
            </td>
            <td>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    onChange={() => {
                        SetVisibilityCommunaute(ENDPOINT, communaute.id, communaute.visible, new Date().toISOString()).then(
                            () => {
                                setCommunautesList(() => {
                                    return communautesList.map((e: any) => {
                                        if (e.id === communaute.id) {
                                            //e.visible = !communaute.visible;
                                            return {
                                                ...e,
                                                visible:  !communaute.visible
                                            }
                                        }
                                        return e;
                                    });
                                });
                            })

                    }}
                    checked={communaute.visible}
                />
            </td>
            <td>{members != -1 && members}</td>
            <td>
                <Button className="deletebnt" variant="primary"
                        onClick={() => {
                            setModalDeleteShow(true);
                            setToBeDelete(communaute.id);
                        }}
                > {trashIcon}
                </Button>
            </td>
        </tr>
    )
}


export default TablCommunaute