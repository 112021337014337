import React, {useState} from "react";

import {
    eyeIcon,
    closeEyeIcon,
    groupIcon,
    individuelIcon,
    parametresIcon,
    paraGroupIcon,
    paraIndividuIcon, tagesIcon
} from './icons'
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Visualization from "./container/Visualization";
import AddCommunaute from "./container/communaute/AddCommunaute";
import AddIndividual from "./container/individu/AddIndividual";
import SettingCommunaute from "./container/communaute/SettingCommunaute";
import SettingIndividu from "./container/individu/SettingIndividu";
import SettingTag from "./container/tag/SettingTag";

function Menu(props: any) {

    const [styleDisplayState, setstyleDisplayState] = useState(false)

    let toggleMenu = (index: string) => {
        props.setToggleState(index);
    };

    const styles = {
        fontSize: 30,
        display: "none"
    }

    if (styleDisplayState) {
        styles.display = "block"
    } else {
        styles.display = "none"
    }

    return (

        <div className="menu">
            <Row>
                <Col>
                    <div className="main-menu">
                        <button
                            className={props.toggleState === "visualization" ? "menuitem menuitem-active" : "menuitem"}
                            onClick={() => {
                                toggleMenu("visualization");
                                setstyleDisplayState(false)
                            }}
                            title="Visualisation de l'utilisateur"
                        >
                            {props.toggleState === "visualization" ? eyeIcon : closeEyeIcon}
                            {/*Visualisation de l'utilisateur*/}
                        </button>

                        <button
                            className={props.toggleState === "addCommunity" ? "menuitem menuitem-active" : "menuitem"}
                            onClick={() => {
                                toggleMenu("addCommunity");
                                setstyleDisplayState(false)
                            }}
                            title="Créer une communauté"
                        >
                            {groupIcon}
                            {/*Créer une communauté*/}
                        </button>
                        <button
                            className={props.toggleState === "addIndividual" ? "menuitem menuitem-active" : "menuitem"}
                            onClick={() => {
                                toggleMenu("addIndividual");
                                setstyleDisplayState(false);
                            }}
                            title="Ajouter un individuel"
                        >
                            {individuelIcon}
                            {/*Ajouter un individuel*/}
                        </button>
                        <button
                            className={
                                props.toggleState === "settingCommunitys" ||
                                props.toggleState === "settingIndividuals" ||
                                props.toggleState === "settingTags" ? "menuitem menuitem-active" : "menuitem"}
                            onClick={() => {
                                toggleMenu("settingCommunitys");
                                setstyleDisplayState(true)
                            }}
                            title="Paramétres"
                        >
                            {parametresIcon}
                            {/*Paramétres*/}
                        </button>
                    </div>
                </Col>
                <Col className="myTitle">
                    <MyTitle toggleState = {props.toggleState}/>
                </Col>
            </Row>

                    <div style={styles} className="setting-menu">
                        <button
                            className={props.toggleState === "settingCommunitys" ? "settngitem settngitem-active" : "settngitem"}
                            onClick={() => toggleMenu("settingCommunitys")}
                            title="st communauté*"
                        >
                            {paraGroupIcon}
                            {/*st communauté*/}
                        </button>

                        <button
                            className={props.toggleState === "settingIndividuals" ? "settngitem settngitem-active" : "settngitem"}
                            onClick={() => toggleMenu("settingIndividuals")}
                            title="st individual"
                        >
                            <a href="https://getbootstrap.com/docs/4.0/components/tooltips/"> </a>{paraIndividuIcon}
                            {/*st individual*/}
                        </button>

                        <button
                            className={props.toggleState === "settingTags" ? "settngitem settngitem-active" : "settngitem"}
                            onClick={() => toggleMenu("settingTags")}
                            title="St tags"
                        >
                            {tagesIcon}
                            {/*St tags*/}
                        </button>
                    </div>
        </div>
    )
}

function MyTitle({toggleState}:any){
    let titleContainer = (toggle:string) =>{
        switch (toggle){
            case "visualization" :
                return "Visualisation de l'utilisateur"
            case "addCommunity" :
                return "Créer une communauté"
            case "addIndividual"  :
                return "Ajouter un individuel"
            case "settingCommunitys" :
                return "Paramétres de Communitys "
            case "settingIndividuals" :
                return "Paramétres de Individuals "
            case "settingTags" :
                return "Paramétres de Tags"
            default :
                console.log ("titleContainer function finiched with a default value please check the index value")
                break
        }
    }

    return(
        <h1>{titleContainer(toggleState)}</h1>
    )
}

export default Menu
