import React, {useEffect, useState} from "react";
import {fetchApi} from "@julienbdx/nous-sommes-common";
import {ENDPOINT} from "../../../config/config";
import {TagAdmin, tagsAdminFromHydra} from "../../../classes/tagAdmin";
import TablTag from "./TablTag";


export const getTags = (setTagsList: any) => {
    fetchApi(ENDPOINT, "tags", {credentials: "include"})
        .then((res: any) => {
            setTagsList(tagsAdminFromHydra(res))
            // console.log(tagsAdminFromHydra(res));
        })
        .then(() => {
            return true
        });
    return false;
}

function SettingTag() {

    const [tagsList, setTagsList] = useState<TagAdmin[]>([])
    getTags(setTagsList);
    return (
        <div>
            {/*<h2>Seting Tags</h2>*/}
            <TablTag tagsList={tagsList} setTagsList={setTagsList}/>
        </div>
    )
}


export default SettingTag