import {fetchApi} from "@julienbdx/nous-sommes-common";
import {ENDPOINT} from "../../../config/config";


export function createIndividu(prenom: string, communaute: string, tags: String[], nom: string, telephone: string, mail: string, adresse: string, date: string, phrase: string, urlVidio: string) {
    const myHeaders = new Headers()
    const options = {
        method: "POST",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
        credentials: "include",
        body: JSON.stringify({
            nom: nom,
            prenom: prenom,
            telephone: telephone,
            mail: mail,
            adresse: adresse,
            phrase: phrase,
            communaute: "/api/communautes/" + communaute,
            video: urlVidio,
            tags: tags.map((i) => {
                return "/api/tags/" + i
            })
            // datePublication: datePublication,
            // slug: slug,
        }),
    }
    fetchApi(ENDPOINT, "/api/individus", options).then((result: any) => {
        // console.log("Résultat création un individu : ", result)
    })
}