import React from "react"
import Visualization from "./container/Visualization";
import AddCommunaute from "./container/communaute/AddCommunaute";
import AddIndividual from "./container/individu/AddIndividual";
import SettingCommunaute from "./container/communaute/SettingCommunaute";
import SettingIndividu from "./container/individu/SettingIndividu";
import SettingTag from "./container/tag/SettingTag";

function ItemContainer(props:any){

    let choisContainer = (toggle:string) =>{
        switch (toggle){
            case "visualization" :
                return <Visualization />
            case "addCommunity" :
                return <AddCommunaute communaute ={null} />
            case "addIndividual"  :
                return <AddIndividual />
            case "settingCommunitys" :
                return <SettingCommunaute />
            case "settingIndividuals" :
                return <SettingIndividu />
            case "settingTags" :
                return <SettingTag />
            default :
                console.log ("choisContainer function finiched with a default value please check the index value")
                break
        }
    }

    return (
        <div className="choice-container">
            {choisContainer(props.toggle)}
        </div>
    )
}

export default ItemContainer