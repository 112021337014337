import {fetchApi} from "@julienbdx/nous-sommes-common";
import React from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {ENDPOINT} from "../../../config/config";


function DeleteTag(props: any) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <h4>supprimer</h4>
                <p>
                    vous etes sûr que voeuler supprimer ce communaute ?
                </p>
                <Modal.Footer>
                    <Button className="btn-success" onClick={props.onHide}>Non</Button>
                    <Button className="btn-danger"
                            onClick={() => {
                                deleteALine(ENDPOINT, "tags/" + props.tobedelete)
                                    .then(() => {
                                        props.setTagsList(() => {
                                            return props.tagsList.filter((e: any) => {
                                                if (e.id !== props.tobedelete) return e;
                                            });
                                        });
                                    })
                                    .then(props.onHide)
                            }}
                    >Yes
                    </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}


export function deleteALine(endpoint: string | undefined, id: string): any {
    const options = {
        method: "DELETE",
        cache: "default",
        credentials: "include",
        Accept: '*/*'
    }
    return fetchApi(endpoint, id, options)
}

export default DeleteTag