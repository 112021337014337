import React, {useEffect, useState} from "react";
import {fetchApi} from "@julienbdx/nous-sommes-common";
import {ENDPOINT} from "../../../config/config";

export function MembersCommunaute(id:number){
    const [members,setMembers] = useState(-1);
    useEffect(() => {
        fetchApi(ENDPOINT, "communautes/" + id + "/individuses", {credentials: "include"})
            .then((res: any) => {
                setMembers(res["hydra:totalItems"]);
            })
    },[id] )
    return members;
}