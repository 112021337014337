import React, { useState} from "react";

export function TagsInput(props :any ) {
    const [tags, setTags] = useState(props.tags)
    const removeTags = (indexToRemove: number) => {
        setTags([...tags.filter((_: any, index: number) =>
            index !== indexToRemove)]);
    };
    const addTags = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.currentTarget.value !== "") {
            setTags([...tags, event.currentTarget.value]);
            props.selectedTags([...tags, event.currentTarget.value]);
            event.currentTarget.value = "";
        }

    };
    return (
        <div className="tags-input">
            <ul id="tags">
                {props.tags.length >0 &&tags.map((tag:string, index:number) => (
                    <li key={index} className="tag" >
                        <span className='tag-title'>{tag}</span>
                        <span className='tag-close-icon'
                              onClick={() => removeTags(index)}
                        >
							x
						</span>
                    </li>
                ))}
            </ul>
            <input
                type="text"
                onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
                placeholder="Cliquez entrée  pour ajouter le tag"
            />
        </div>
    );
}