import {ICommunaute,Communaute} from "@julienbdx/nous-sommes-common";
import {IHydraResponse} from "@julienbdx/nous-sommes-common/lib/classes/hydra";

interface ICommunauteAdmin extends ICommunaute{
    visible:boolean;

}

export class CommunauteAdmin extends Communaute implements ICommunauteAdmin {
    visible:boolean = false ;

    static fromHydra(data:any): CommunauteAdmin {
        const communauteAdmin = super.fromHydra(data) as CommunauteAdmin;
        communauteAdmin.visible = data.visible ;
        return communauteAdmin ;
    }
}

export const communautesAdminFromHydra = (hydraResponse: IHydraResponse | any[]): CommunauteAdmin[] => {
    return 'hydra:member' in hydraResponse
        ? hydraResponse['hydra:member'].map((item: any) => CommunauteAdmin.fromHydra(item))
        : hydraResponse.map((item: any) => CommunauteAdmin.fromHydra(item));
};
export const oneCommunauteFromHydra =   (hydraResponse: IHydraResponse | any[]): CommunauteAdmin => {
    return 'hydra:member' in hydraResponse ?
        CommunauteAdmin.fromHydra (hydraResponse['hydra:member'])
        : CommunauteAdmin.fromHydra(hydraResponse);
}
