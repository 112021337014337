import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faEye,
    faEyeSlash,
    faUsers,
    faUserPlus,
    faFeatherAlt,
    faUsersCog,
    faUserCog,
    faHashtag,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons'

// container icons :
export const eyeIcon = <FontAwesomeIcon icon={faEye} className='myIcon'/>
export const closeEyeIcon = <FontAwesomeIcon icon={faEyeSlash} className='myIcon'/>

export const groupIcon = <FontAwesomeIcon icon={faUsers} className='myIcon'/>

export const individuelIcon = <FontAwesomeIcon icon={faUserPlus} className='myIcon'/>

export const parametresIcon = <FontAwesomeIcon icon={faFeatherAlt} className='myIcon'/>

export const paraGroupIcon = <FontAwesomeIcon icon={faUsersCog} className='myIcon'/>

export const paraIndividuIcon = <FontAwesomeIcon icon={faUserCog} className='myIcon'/>

export const tagesIcon = <FontAwesomeIcon icon={faHashtag} className='myIcon'/>

//////

export const trashIcon = <FontAwesomeIcon icon={faTrashAlt} className='myIcon'/>
