import {fetchApi} from "@julienbdx/nous-sommes-common";
import {ENDPOINT} from "../../../config/config";
import {useState} from "react";

export function createCommunaute(id:number,method:string,nome: string, forme: string, couleur: string, visible: boolean, datePublication: string, slug: string) {
    // console.log("id in create communaute : ",id )
    const cId = id ?"/"+id.toString() : ""
    // console.log("le vrai cId : "+"/api/communautes"+cId)

    const myHeaders = new Headers()
    // the condition if id : to change th headers juste if the method is PATCH
    if (id)
        myHeaders.set("Content-Type", "application/merge-patch+json")
    const options = {
        method: method,
        headers: myHeaders,
        mode: "cors",
        cache: "default",
        credentials: "include",
        body: JSON.stringify({
            libelle: nome,
            visible: visible,
            forme: "/api/formes/" + forme,
            couleur: "/api/couleurs/" + couleur,
            datePublication: datePublication,
            slug: slug,
        }),
    }
    return fetchApi(ENDPOINT, "/api/communautes"+cId, options)
    // then((result: any) => {
    //     // console.log("Résultat création communauté : ", result)
    // })
}

