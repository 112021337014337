import React, {ChangeEvent, useState} from 'react';
import {ENDPOINT} from "../config/config";
import Form from "react-bootstrap/Form"
import Button from 'react-bootstrap/Button'
import '../../src/styles/login.scss'
import {fetchApi} from "@julienbdx/nous-sommes-common";

const className = (...arr: any[]) => arr.filter(Boolean).join(' ')


function FormulaireConnexion(props: any) {
    const [username, setUsername] = useState("admin@admin.fr")
    const [password, setPassword] = useState("1111")

    const connect = () => {
        const myHeaders = new Headers()
        // eslint-disable-next-line no-debugger
        const options = {
            method: "POST",
            headers: myHeaders,
            mode: "cors",
            cache: "default",
            credentials: "include",
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        }
        fetchApi(ENDPOINT, "/api/login", options)
            .then((data: any) => {
                // Bien authentifié, on a reconnu l'utilisateur
                props.setUtilisateur(data)
            })
            .catch((error: Error) => {
                // eslint-disable-next-line no-console
                console.error(error)
            })
    }

    return (
        <FormulaireConnexionContainer
            connect= {connect}
            setName= {setUsername}
            setPassword= {setPassword}
        />
    )
}


function FormulaireConnexionContainer(props: any) {
    return (
        <div className="login">
            <Form method="post">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Adresse email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" className="text-muted"
                                  onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                      props.setName(ev.currentTarget.value)
                                  }}/>
                </Form.Group>
                {/*controlId="formBasicPassword"*/}
                <Form.Group className="mb-3">
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control type="password" placeholder="Password"
                                  onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                      props.setPassword(ev.currentTarget.value)
                                  }}
                                  // TODO : onKeyDown={}
                                  required
                                  isInvalid={props.notGoodPas}
                    />
                    <Form.Control.Feedback type="invalid">
                        Veuillez patienter
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="success" type="button" onClick={props.connect}>
                    Se connecter
                </Button>
            </Form>
        </div>
    )
}


export default FormulaireConnexion

