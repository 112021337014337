import React from "react";

export const getTagsIds = (userTags:string[],TagsList:any[]) =>{
    let tagsid  = new Array() ;
    userTags.map((e)=>{
        TagsList.map((elist)=>{
            if(e === elist.libelle)
                tagsid.push(elist.id)
        })
    })
    return tagsid ;
}