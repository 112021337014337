import React, {useEffect, useState} from "react";
import {Communaute, fetchApi} from "@julienbdx/nous-sommes-common";
import {ENDPOINT} from "../../../config/config";
import {communautesAdminFromHydra} from "../../../classes/CommunauteAdmin";
import TablCommunaute from "./TablCommunaute";


function SettingCommunaute() {

    const [communautesList, setCommunautesList] = useState<Communaute[]>([])

    useEffect(() =>{
        fetchApi(ENDPOINT, "communautes",{credentials: "include"})
            .then((res: any) => {
                setCommunautesList(communautesAdminFromHydra(res))
            })
        } , [])

    return (
        <div>
            {/*<h2>Seting Communitys</h2>*/}
            <TablCommunaute communautesList ={communautesList} setCommunautesList={setCommunautesList} />
        </div>
    )
}

export default SettingCommunaute