import React , {useState} from "react";

function Visualization(){
    const [styleDisplayState, setstyleDisplayState] = useState(false)
    return (
        <div>
            <h1> I am in Visualization </h1>
        </div>
    )
}

export default Visualization