import {ITag , Tag } from "@julienbdx/nous-sommes-common";
import {IHydraResponse} from "@julienbdx/nous-sommes-common/lib/classes/hydra";

interface ITagAdmin extends ITag {
    visible:boolean ;
}

export class TagAdmin extends Tag implements ITagAdmin{
    visible :boolean = false ;

    static fromHydra(data: any): TagAdmin {
        // super = Tag dasn extends !
        const  tagAdmin = super.fromHydra(data) as TagAdmin ;
        tagAdmin.visible = data.visible ;
        return tagAdmin ;
    }
}

export const tagsAdminFromHydra = (hydraResponse: IHydraResponse | any[]): TagAdmin[] => {
    return 'hydra:member' in hydraResponse
        ? hydraResponse['hydra:member'].map((item: any) => TagAdmin.fromHydra(item))
        : hydraResponse.map((item: any) => TagAdmin.fromHydra(item));
};

