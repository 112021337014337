import {fetchApi} from "@julienbdx/nous-sommes-common";

function SetVisibilityTag(endpoint: string | undefined, id: string, oldState: boolean): any {
    const options = {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({
            "visible": !oldState
        })
    }
    return fetchApi(endpoint, "tags/"+id, options)
}

export default SetVisibilityTag