import React, {useEffect, useState} from "react";
import {fetchApi} from "@julienbdx/nous-sommes-common";
import {ENDPOINT} from "../../../config/config";
import TablIndividu from "./TablIndividu";
import {IIndividuAdmin, individuAdminFromHydra} from "../../../classes/IndividuAdmin";


function SettingIndividu() {

    const [individusList,setIndividusList ] = useState<IIndividuAdmin[]>( [])

    useEffect(() =>{
        fetchApi(ENDPOINT, "individus",{credentials: "include"})
            .then((res: any) => {
                setIndividusList(individuAdminFromHydra(res))
                console.log(individuAdminFromHydra(res));
            })
        } , [])

    return (
        <div>
            {/*<h2>Seting individus</h2>*/}
            <TablIndividu individusList ={individusList} setIndividusList={setIndividusList} />
        </div>
    )
}

export default SettingIndividu